<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg8 xl8">
      <va-card-content>
        <monta-form :form="newSearchFormStruct" @complete="(data) => submitFormSearch(this, data)"/>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import {submitFormSearch} from "./functions";
import newSearchFormStruct from "./newSearchFormStruct";

export default {
  name: "NewTerm",
  data(){
    return{
      terms: '',
      newSearchFormStruct
    }
  },
  methods: {
    submitFormSearch
  }
}
</script>

<style scoped>
</style>