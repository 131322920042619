import {FetchWs} from "@/functions/FetchWs";

export async function logout(vue: any): Promise<boolean>{
    await FetchWs('bot/logout/' + vue.$route.params.public_id,'POST');
    vue.$router.push({name:'painel-bots'})
    return true;
}

export async function checkBotConnection(vue: any, loader = true): Promise<boolean>{
    if(!vue.$route.params.public_id){
        return false;
    }
    const response = await FetchWs('bot/check-connection/' + vue.$route.params.public_id,'GET',{},{},loader);
    if(response?.success && response.data?.status) {
        vue.qrcode = false
        vue.status = true
    } else {
        if(!vue.$route.params.public_id){
            return false;
        }
        await getQrCode(vue,loader)
    }
    return true;
}

export async function getQrCode(vue: any, loader = true): Promise<boolean>{
    const response = await FetchWs('bot/get-qr-code/' + vue.$route.params.public_id,'GET',{},{},loader);
    if (response?.success && response.data?.qrcode) {
        vue.qrcode = response.data.qrcode
    }
    return true;
}

export async function getBotInfo(vue: any): Promise<boolean>{
    const response = await FetchWs('bot/get-infos/' + vue.$route.params.public_id);
    if(response?.success && response.data) {
        vue.bot = response.data
    }
    return true;
}