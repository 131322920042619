<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md9 lg9 xl9">
      <va-card-title>
        <div class="row justify-center align-end">
          <va-button class="flex" @click="() => salvaPerms(this)">Salvar</va-button>
        </div>
      </va-card-title>
      <va-card-content>
        <va-card outlined style="overflow: hidden">
          <va-data-table
            v-if="loaded"
            :items="perms"
            :columns="columns"
            :selectable="true"
            :hoverable="true"
            :striped="true"
            v-model="selectedItems"
            :select-mode="'multiple'"
            sticky-header
            :wrapper-size="500"
            :virtual-scroller="perms.length > 8 "
          />
        </va-card>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import {getPerms, getPermsActive, salvaPerms} from "./EditarPermissoesFunctions"

export default {
  name: "EditarUsuario",
  props:['id'],
  async mounted() {
    await getPerms(this)
    this.selectedItems = getPermsActive(this)
    this.loaded = true;
  },
  data() {
    const columns = [
      { key: 'per_id', label: 'label', sortable: false },
      { key: 'per_key', label: 'KEY', sortable: true },
      { key: 'per_descricao', label: 'NOME', sortable: true },
    ]

    return {
      loaded:false,
      perms: [],
      columns,
      selectedItems: [],
      formCarregado: 0,
      idUser: this.$route.params.id
    }
  },
  methods:{
    salvaPerms
  },
}
</script>

<style scoped>

</style>