import {BotInterface} from "./BotsInterfaces";
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any): Promise<void>
{
    const response = await FetchWs('bot/get-all');
    if(response && response.success && Array.isArray(response.data)){
        if(response.data.length === 1 && !response.data[0].bot_connected){
            vue.$router.push({name:'painel-bots-detalhes',params: {public_id: response.data[0].bot_public_id}})
        }
        vue.table.items = assertBots(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertBots(objs: BotInterface[]): BotInterface[]
{
    objs.forEach((value:BotInterface,key:number) => {
        objs[key].detalhes = value.bot_public_id
    })
    return objs;
}