<template>
  <div class="row justify-center align-center">
    <div  v-if="status === false" class="flex xs12 sm12 md12 lg9 xl9">
      <va-card  class="flex mb-4">
        <va-card-content>
          <h3 class="va-h3" style="text-align: center">WhatsApp Status: <va-chip color="danger">Desconectado</va-chip></h3>
          <br>
          <qr-code v-if="qrcode" :qrcode="qrcode"/>
        </va-card-content>
      </va-card>
    </div>
    <div v-if="status" class="flex xs12 sm12 m6 lg6 xl6">
      <va-card  class="flex mb-4">
        <va-card-content>
          <h3 class="va-h3" style="text-align: center">WhatsApp Status: <va-chip color="success">Conectado</va-chip></h3>
          <br>
          <div class="bot__opcoes">
            <div class="row justify-center align-center">
              <div class="flex md12">
                <div style="text-align: center">
                  <p>Seu WhatsApp está conetado! Já pode usar o sistema.</p>
                  <br>
                  <va-button icon-right="logout" color="danger" gradient @click="() => logout(this)">
                    Desconectar
                  </va-button>
                </div>
              </div>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import QrCode from "./QrCode";
import {checkBotConnection, logout} from "./functions";
import {sleep} from "@/functions/Sleep";

export default {
  name: "BotDetalhes",
  components: {QrCode},
  async mounted(){
    let loader = true;
    this.mounted = true;
    while (this.mounted && !this.status){
      await checkBotConnection(this,loader)
      await sleep(500)
      if(this.qrcode){
        loader = false
      }
    }
  },
  beforeUnmount(){
    this.mounted = false;
  },
  data(){
    return {
      mounted: true,
      status: false,
      qrcode: false
    }
  },
  methods: {
    logout
  }
}
</script>

<style scoped>
.bot__opcoes__botao{
  width: 100%;
  height: 60px;
}
</style>