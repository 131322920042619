<template>
  <monta-form :form="formLogin" @complete="submitFormLogin"/>
</template>

<script>
import FormLoginStruct from "./FormLoginStruct";
import {FetchWs} from "@/functions/FetchWs";

export default {
  name: 'login',
  data() {
    return {
      formLogin: {
        struct: FormLoginStruct
      }
    }
  },
  methods: {
    async submitFormLogin(data){
      const base64 = btoa(data.login_email + ':' + data.login_pass);
      const response = await FetchWs('auth/login', 'GET',{},{
        Authorization: "Basic " + base64
      });
      if(response?.success && response?.data?.jwt) {
        localStorage.setItem('JWT_TOKEN', response.data.jwt);
        this.$router.push('/')
      }
    }
  },
}
</script>
