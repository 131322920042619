export default {
    name: "table-bots",
    ignorePersonalizar: true,
    columns: [
        { key: 'detalhes', label: 'Detalhes', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'bot_connected', label: 'Status', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'bot_public_id', label: 'ID', sortable: false, alignHead: 'center',align: 'center', verticalAlign: 'middle', },
        { key: 'bot_name', label: 'Nome', sortable: false, alignHead: 'center',align: 'center', verticalAlign: 'middle', },
        { key: 'bot_type', label: 'Tipo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'pla_name', label: 'Plano', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    items: [],
}