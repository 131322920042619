<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md4 lg4 xl4">
      <va-card-content>
        <monta-form :form="form" @complete="(data)=>changePass(data, this)"/>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import {changePass} from "./ChangePasswordFunctions"
import FormStruct from "./ChangePasswordFormStruct";

export default {
  name: "ChangePassword",
  props:['id'],
  data() {
    return {
      form: {
        struct: FormStruct
      },
      idUser: this.$route.params.id
    }
  },
  methods:{
    changePass
  },
}
</script>

<style scoped>

</style>