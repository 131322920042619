export default {
    struct: [
        [
            {
                col         : 12,
                name        : "busca_nome",
                value       : "",
                type        : "text",
                placeholder : "Ex.: Contabilidades",
                label       : "Nome da busca",
                required    : true,
            },
            {
                col         : 12,
                name        : "busca_termos",
                value       : "",
                type        : "textarea",
                lineCounter : true,
                placeholder : "Ex.: Contabilidade em Curitiba",
                label       : "Termos a serem procurados",
                required    : true,
                minRows     : 8,
            }
        ]
    ]
}