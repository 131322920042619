<template>

  <div class="row justify-space-betwee align-center">
    <div class="flex md6">
      <h4 class="va-h4">Conecte o seu WhatsApp</h4>
      <div class="mb-4">
        <h6 class="va-h6">Conectar ao {{nome}} é igual conectar no WhastApp WEB:</h6>
        <p><br><br>
          1. Abra o WhatsApp em seu telefone<br><br>

          2. Toque em Menu ou Configurações e selecione WhatsApp Web<br><br>

          3. Aponte seu telefone para o QRCode
        </p>
        <br>
      </div>
    </div>
    <div v-if="qrcode" class="flex md6" style="text-align: right">
      <img width="300" :src="qrcode"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "QrCode",
  props:['qrcode'],
  data() {
    return {
      'nome': process.env.VUE_APP_NOME
    }
  }

}
</script>

<style scoped>

</style>